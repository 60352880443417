import { makeObservable, observable, action, autorun } from 'mobx'
import check from '../utils/check'

class UserStore {
  moneyInfo = null
  questLog = null
  userInfo = null
  moneyStandardTime = null
  questMarkAvailable = null
  ongoingQuest = null
  onboarding = null
  onboardingToast = null
  miniGame = null
  followList = []
  teamList = []
  // 앱 push 를 통해 들어온 경우, 이동해야 할 위치를 저장
  initialLandingPage = this.initializeInitialLandingPage()
  exposedToRepostGuideAt = null

  constructor() {
    makeObservable(this, {
      userInfo: observable,
      moneyInfo: observable,
      questLog: observable,
      moneyStandardTime: observable,
      questMarkAvailable: observable,
      ongoingQuest: observable,
      onboarding: observable,
      onboardingToast: observable,
      miniGame: observable,
      followList: observable,
      teamList: observable,
      initialLandingPage: observable,
      exposedToRepostGuideAt: observable,

      setMoneyInfo: action,
      setQuestLog: action,
      setUserInfo: action,
      setMoneyStandardTime: action,
      setQuestMarkAvailable: action,
      setOngoingQuest: action,
      setOnboarding: action,
      setOnboardingToast: action,
      setMiniGame: action,
      setFollowList: action,
      setTeamList: action,
      setInitialLandingPage: action,
      initializeInitialLandingPage: action,
      setExposedToRepostGuideAt: action,
    })
  }

  initializeInitialLandingPage() {
    this.initialLandingPage = {
      pathname: '', // page 의 URL Path
      queryObj: {}, // page 의 Query Parameter Object
    }
  }

  setInitialLandingPage(initialLandingPage) {
    this.initialLandingPage = initialLandingPage
  }

  setUserInfo(userInfo) {
    this.userInfo = userInfo
  }

  setMoneyInfo(moneyInfo) {
    this.moneyInfo = moneyInfo
  }

  setQuestLog(questLog) {
    this.questLog = questLog
  }

  setMoneyStandardTime(moneyStandardTime) {
    this.moneyStandardTime = moneyStandardTime
  }

  setQuestMarkAvailable(questMarkAvailable) {
    this.questMarkAvailable = questMarkAvailable
  }

  setOngoingQuest(ongoingQuest) {
    this.ongoingQuest = ongoingQuest
  }

  setOnboarding(onboarding) {
    this.onboarding = onboarding
  }

  setOnboardingToast(onboardingToast) {
    this.onboardingToast = onboardingToast
  }

  setMiniGame(miniGame) {
    this.miniGame = miniGame
  }

  setFollowList(followList) {
    this.followList = followList
  }

  setTeamList(teamList) {
    this.teamList = teamList
  }

  setExposedToRepostGuideAt(exposedToRepostGuideAt) {
    this.exposedToRepostGuideAt = exposedToRepostGuideAt
  }
}

const container = {}
container.instance = new UserStore()
export default container.instance

autorun(() => {
  const sprite = [
    {
      type: 'dailyCheckIn',
      icon: '/images/calendar.png',
      title: '매일 출석하기',
      desc: '1000, 하루 1번 가능',
      buttonText: '출석하기',
      coin: 1000,
      codepush: '1.0.0',
    },
    {
      type: 'dailyTenVideos',
      icon: '/images/quests/tenVideos.png',
      title: '비디오 10개 보기',
      desc: '10000, 하루 1번 가능',
      buttonText: '시청하기',
      coin: 10000,
      codepush: '1.0.0',
    },
  ]

  const checkAvailable = (userCodepush, questsObj) =>
    sprite
      .filter((elem) => {
        const codepushAvailable = check.checkCodepush(
          userCodepush,
          elem?.codepush,
        )
        return codepushAvailable
      })
      .map((each) => {
        const target = each?.type
        if (questsObj?.[target] !== false) {
          return {
            ...each,
            available: true,
          }
        }
        return {
          ...each,
          available: false,
        }
      })

  const userCodepush = container.instance.userInfo?.codepushVersion
  const questsObj = container.instance.questLog

  const availableQuests = checkAvailable(userCodepush, questsObj)
  const questMarkAvailable = availableQuests?.find((each) => each?.available)
  container.instance.setQuestMarkAvailable(!!questMarkAvailable)
})
