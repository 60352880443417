import React, { useEffect, useState } from 'react'
import { useSearchParam } from 'react-use'
import { observer } from 'mobx-react-lite'

import Player from '../molecules/player'
import Carousel from '../templates/carousel'
import Loading from '../atoms/loading'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'
import VideoStore from '../../stores/VideoStore'
import InviteFollowees from '../molecules/repost/inviteFollowees'
import ModalStore from '../../stores/ModalStore'

const Contents = ({ elem, index, currentIndex }) => {
  if (currentIndex > index + 1 || currentIndex < index - 1) {
    return <div id={index} aria-hidden='true' className='w-screen h-screen' />
  }

  return <Player src={elem} index={index} currentIndex={currentIndex} />
}

const Videos = observer(() => {
  const token = useSearchParam('token') || AuthStore?.token
  const [isLoading, setIsLoading] = useState(true)
  const [addedInviteGuide, setAddedInviteGuide] = useState(false)
  const [followeesToRecommend, setFolloweesToRecommend] = useState([])

  // Video 가 있고, 현재보고 있는 영상 뒤에 불러온 영상이 2개일 뿐일 때 (False) & Token 이 있을 때 불러온다
  useEffect(() => {
    const fetchData = async () => {
      if (
        VideoStore?.videos?.length &&
        VideoStore.videos.length - VideoStore.currentIndex !== 2
      ) {
        return
      }
      if (!token) return
      const result = await backendApis.videos(token, 'GET')
      if (result?.status === 200 && result?.data?.length) {
        VideoStore.setVideos([
          ...(VideoStore?.videos || []),
          ...result.data.filter(
            (each) =>
              !VideoStore?.videos?.map((elem) => elem?.url).includes(each?.url),
          ),
        ])
      }

      setIsLoading(false)
    }
    fetchData()
  }, [
    VideoStore?.videos?.length &&
      (VideoStore?.videos?.length || 0) - (VideoStore?.currentIndex || 0) !== 2,
    token,
  ])

  const getFolloweesToReco = async () => {
    const result = await backendApis.followReco(token, 'GET')

    // if status 200 and data length > 0
    if (result.status === 200 && result.data?.length > 0) {
      // if (!followeesToRecommend?.length) return

      // 처음 5번 안에 보여지도록 한다.
      const minIndexToShowGuide = Math.min(VideoStore?.videos?.length, 5)

      const randomIndex =
        Math.floor(Math.random() * (minIndexToShowGuide - 1)) + 1

      // Step 3: Insert the object at the random index
      VideoStore?.videos.splice(randomIndex, 0, { type: 'invite-followees' })
      setFolloweesToRecommend(result.data)
      setAddedInviteGuide(true)
    }
  }
  // Trigger Invite Full-Screen
  // 조건: 비디오 탭에 들어왔을 때 (5개 이하일 때) + 30% 확률
  // + 아직 초대 혹은 무시하지 않은 올팜 친구가 있으면 이 페이지를 노출한다.
  useEffect(() => {
    if (addedInviteGuide) return
    if (VideoStore?.videos?.length > 0) {
      if (Math.random() > 0.7) return
      getFolloweesToReco()
    }
  }, [VideoStore?.videos?.length > 0])

  return (
    <Carousel>
      {VideoStore?.videos.map((elem, index) => {
        if (
          elem?.type === 'invite-followees' &&
          followeesToRecommend?.length > 0
        ) {
          return (
            <InviteFollowees
              key={`invite-followees-${index.toString()}`}
              followees={followeesToRecommend}
            />
          )
        }

        return (
          <Contents
            key={(elem?.url || elem) + index.toString()}
            elem={elem?.url}
            index={index}
            currentIndex={VideoStore?.currentIndex || 0}
          />
        )
      })}

      <Loading isLoading={isLoading} />
    </Carousel>
  )
})

export default Videos
